import { Breadcrumbs, Stack, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const AWSBreadCrumpUsers = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const toCamelCase = (str) => {
    return str
      .split(/(?=[A-Z])/)
      .join(" ")
      .replace(/\b\w/g, (char) => char.toUpperCase())
      .replace(/\s+/g, "");
  };

  return (
    <Stack spacing={5}>
      {pathnames[1] === "users" && (
        <>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
            style={{ fontWeight: "bold" }}
            sx={{ fontSize: "18px" }}
          >
            {pathnames.slice(1).map((value, index) => {
              const to = `/${pathnames.slice(0, index + 2).join("/")}`;
              const isLast = index === pathnames.length - 2;
              const displayValue =
                value === "userDetails" ? "UserDetails" : toCamelCase(value);

              return isLast ? (
                <Typography color="textPrimary" sx={{ fontSize: "18px" }}>
                  {displayValue}
                </Typography>
              ) : (
                <Link key={to} to={to} sx={{ fontSize: "18px" }}>
                  {displayValue}
                </Link>
              );
            })}
          </Breadcrumbs>
        </>
      )}
    </Stack>
  );
};

export default AWSBreadCrumpUsers;
