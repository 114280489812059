import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const EC2Instances = ({
  ec2Datas,
  getAllEntityAllowedforInstance,
  selectedResourceInstance,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredec2Datas = ec2Datas.filter((ec2Data) =>
    ec2Data.instanceName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            height: "fit-content",
            "& th": {
              background: "#233044",
              color: "#fff",
            },
            "& td, & th": {
              border: "1px solid #233044",
              fontSize: "16px",
            },
          }}
          size="large"
        >
          <TableHead>
            <TableRow>
              <TableCell>Resource Instances</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ec2Datas.length > 0 ? (
              <>
                <TableRow>
                  <TableCell colSpan={1}>
                    <TextField
                      variant="outlined"
                      placeholder="Search Instance"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                {filteredec2Datas.map((ec2Data, index) => (
                  <TableRow
                    key={index}
                    selected={ec2Data.instanceId === selectedResourceInstance}
                    onClick={() => {
                      getAllEntityAllowedforInstance(ec2Data);
                    }}
                    sx={{
                      "&:hover": {
                        background: "#f5f5f5",
                        cursor: "pointer",
                      },
                      "&.Mui-selected": {
                        background: "#233044 !important",
                        "& td, & th": {
                          color: "#fff",
                        },
                      },
                      "& td, & th": {
                        border: "1px solid #233044",
                        fontSize: "16px",
                      },
                    }}
                  >
                    <TableCell>{ec2Data.instanceName}</TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell>
                  <Typography colSpan={1} align="center">
                    No Instance Found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EC2Instances;
