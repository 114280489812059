import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Grid,
} from "@mui/material";
import PolicyIcon from "@mui/icons-material/Policy";
import AssignmentIcon from "@mui/icons-material/Assignment";
import axios from "axios";
import { NGROK } from "../../../APIs";
import SecurityIcon from "@mui/icons-material/Security";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { myLocalStorage } from "../../../components/StorageHelper";

const Wrapper = styled.div`
  max-height: 500px;
`;

const useStyles = makeStyles({
  root: {
    maxHeight: "500px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
});

const AWSGroups = () => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [groupUsers, setGroupUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rolesDetailsModalOpen, setRolesDetailsModalOpen] = useState(false);
  const [rolesPermissionJson, setRolesPermissionJson] = useState([]);
  const [policiesDetailsModalOpen, setPoliciesDetailsModalOpen] =
    useState(false);
  const [policiesDetails, setPoliciesDetails] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  const handleUsersSearchChange = (event) => {
    const search = event.target.value;
    const filteredUsers = groupUsers.filter(
      (user) =>
        user.UserName &&
        user.UserName.toLowerCase().includes(search.toLowerCase()),
    );
    console.log(filteredUsers);
    setFilteredUsers(filteredUsers);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setSelectedGroup(null);
    setSelectedUser(null);
  };

  const handleGroupClick = (group) => {
    setSelectedGroup(group);
    const usersInGroup = users.filter((user) =>
      user.groups.some((g) => g.groupName === group.groupName),
    );
    setGroupUsers(usersInGroup);
    setSelectedUser(null);
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filteredGroups = groups.filter((group) =>
    group.groupName.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const getAllUsers = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/getAllIAMUsers?tenantName=${latestTenant}`,
    );
    return response.data;
  };

  const getAllGroups = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/list-iam-groups?tenantName=${latestTenant}`,
    );
    return response.data;
  };

  const getAllRoles = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/list-iam-roles?tenantName=${latestTenant}`,
    );
    return response.data;
  };

  const combinedPermissionandGetRoles = (users, roles) => {
    return users.map((user) => {
      const items = getAllConsolidatedPermissions(user);
      const matchingRoles = [];

      roles.forEach((role) => {
        role?.inlinePolicies?.forEach((policy) => {
          if (items.includes(policy.policyArn)) {
            matchingRoles.push({
              roleName: role.roleName,
              roleId: role.roleId,
              roleArn: role.roleArn,
            });
          }
        });

        role?.attachedPolicies?.forEach((policy) => {
          if (items.includes(policy.policyArn)) {
            matchingRoles.push({
              roleName: role.roleName,
              roleId: role.roleId,
              roleArn: role.roleArn,
            });
          }
        });
      });

      user.roles = matchingRoles;

      return user;
    });
  };

  const fetchData = async (initialLoad = false) => {
    try {
      const [groupsData, usersData, rolesData] = await Promise.all([
        getAllGroups(),
        getAllUsers(),
        getAllRoles(),
      ]);

      let userWithRoles = combinedPermissionandGetRoles(usersData, rolesData);

      setUsers(userWithRoles);
      setGroups(groupsData);

      setLoading(false);
      if (initialLoad && groupsData.length > 0) {
        const group = groupsData[0];
        setSelectedGroup(group);
        const usersInGroup = usersData.filter((user) =>
          user.groups.some((g) => g.groupName === group.groupName),
        );
        setGroupUsers(usersInGroup);
        setSelectedUser(null);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const fetchPolicyDocDetails = async (policyArns) => {
    const data = {
      policyArn: policyArns,
    };

    try {
      const response = await axios.post(
        `${NGROK}/api/aws/${latestTenant}/list-iam-specific-policies`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching policy details:", error);
      return null;
    }
  };

  const detailsofRoles = async (roleID) => {
    try {
      const resp = await axios.get(`${NGROK}/api/aws-roles/${roleID}`);
      const data = resp.data;

      const consolidatedPolicies = [
        ...data.InLinePermissions,
        ...data.AttachedPermissions,
      ];

      const policyArns = consolidatedPolicies.map((policy) => policy.PolicyArn);

      const policyDocDetails = await fetchPolicyDocDetails(policyArns);

      const { RoleId, RoleName, CreatedDate } = data;
      const formattedPolicies = policyDocDetails.map((policy) => ({
        PolicyId: policy.PolicyId,
        PolicyName: policy.PolicyName,
        PolicyArn: policy.PolicyArn,
        PolicyDocument: policy.PolicyDocument,
      }));
      const combinedJson = [
        {
          RoleId,
          RoleName,
          CreatedDate,
          policies: formattedPolicies,
        },
      ];
      setRolesPermissionJson(combinedJson);
      setRolesDetailsModalOpen(true);
    } catch (error) {
      console.error("Error fetching role details:", error);
    }
  };

  const detailsofPermission = async (ploicyARN) => {
    const policyDocDetails = await fetchPolicyDocDetails(ploicyARN);
    const formattedPolicies = {
      PolicyId: policyDocDetails.policyId,
      PolicyName: policyDocDetails.policyName,
      PolicyArn: policyDocDetails.policyArn,
      PolicyDocument: policyDocDetails.policyDocument,
    };
    setPoliciesDetails([formattedPolicies]);
    setPoliciesDetailsModalOpen(true);
  };

  const getAllConsolidatedPermissions = (selectedUser) => {
    const inlineUserPolicies = selectedUser?.inlineUserPolicies || [];
    const groupPolicies = selectedUser?.groupPolicies || [];
    const groupInlinePolicies = selectedUser?.groupInlinePolicies || [];
    const attachedUserPolicies = selectedUser?.attachedUserPolicies || [];
    const consolidatedPolicies = [
      ...groupInlinePolicies,
      ...groupPolicies,
      ...inlineUserPolicies,
      ...attachedUserPolicies,
    ];
    return consolidatedPolicies;
  };

  useEffect(() => {
    fetchData(true);
    const interval = setInterval(fetchData, 10000);
    return () => clearInterval(interval);
  }, [latestTenant]);
  useEffect(() => {
    if (selectedGroup) {
      const usersInGroup = users.filter((user) => {
        console.log(user);
        return user.groups.some((g) => g.groupName === selectedGroup.groupName);
      });
      setFilteredUsers(usersInGroup);
    }
  }, [selectedGroup]);

  return (
    <>
      <Box p={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h5">IAM Groups</Typography>
        </Box>
        {loading ? (
          <Box display="flex" justifyContent="center" p={5}>
            <CircularProgress />
          </Box>
        ) : (
          <Stack direction="row" spacing={4}>
            <Box width="30%">
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    height: "fit-content",
                    "& th": {
                      background: "#233044",
                      color: "#fff",
                    },
                    "& td, & th": {
                      border: "1px solid #233044",
                      fontSize: "16px",
                    },
                  }}
                  size="large"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Group Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredGroups.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={1} align="center">
                          No Groups Available
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        <TableRow>
                          <TableCell colSpan={1}>
                            <TextField
                              variant="outlined"
                              placeholder="Search groups"
                              value={searchTerm}
                              onChange={handleSearchChange}
                              fullWidth
                            />
                          </TableCell>
                        </TableRow>
                        {filteredGroups.map((group) => (
                          <TableRow
                            key={group.groupName}
                            onClick={() => handleGroupClick(group)}
                            selected={group.groupId === selectedGroup?.groupId}
                            sx={{
                              "&:hover": {
                                background: "#f5f5f5",
                                cursor: "pointer",
                              },
                              "& td, & th": {
                                border: "1px solid #233044",
                                fontSize: "16px",
                              },
                              "&.Mui-selected": {
                                background: "#233044 !important",
                                "& td, & th": {
                                  color: "#fff",
                                },
                              },
                            }}
                          >
                            <TableCell>{group.groupName}</TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box width="30%">
              {selectedGroup && (
                <TableContainer component={Paper}>
                  <Table
                    sx={{
                      height: "fit-content",
                      "& th": {
                        background: "#233044",
                        color: "#fff",
                      },
                      "& td, & th": {
                        border: "1px solid #233044",
                        fontSize: "16px",
                      },
                    }}
                    size="large"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>User Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredUsers.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={1} align="center">
                            No Users Available
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          <TableRow>
                            <TableCell>
                              <TextField
                                variant="outlined"
                                placeholder="Search users"
                                onChange={handleUsersSearchChange}
                                fullWidth
                              />
                            </TableCell>
                          </TableRow>
                          {filteredUsers.map((user) => (
                            <TableRow
                              key={user.userName}
                              selected={
                                selectedUser?.userName === user.userName
                              }
                              onClick={() => handleUserClick(user)}
                              sx={{
                                "&:hover": {
                                  background: "#f5f5f5",
                                  cursor: "pointer",
                                },
                                "& td, & th": {
                                  border: "1px solid #233044",
                                  fontSize: "16px",
                                },
                                "&.Mui-selected": {
                                  background: "#233044 !important",
                                  "& td, & th": {
                                    color: "#fff",
                                  },
                                },
                              }}
                            >
                              <TableCell>{user.userName}</TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
            <Box width="30%">
              {selectedUser && (
                <Box
                  sx={{
                    border: "1px solid",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      background: "#233044",
                      color: "#fff",
                      padding: "10px",
                      borderRadius: "5px",
                      fontSize: "16px",
                      p: 4.8,
                    }}
                  >
                    Details for User: {selectedUser.userName}
                  </Typography>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="User Details Tabs"
                    sx={{ "& .MuiTab-root": { fontSize: 16 } }}
                  >
                    <Tab icon={<AssignmentIcon />} label="Roles" />
                    <Tab icon={<SecurityIcon />} label="Permissions" />
                  </Tabs>
                  <Box mt={2} sx={{ maxHeight: 300, overflowY: "auto" }}>
                    {tabValue === 0 && (
                      <List>
                        {selectedUser.roles.length > 0 ? (
                          selectedUser.roles.map((role) => (
                            <ListItem
                              key={role.RoleId}
                              //onClick={() => detailsofRoles(role.roleId)}
                              sx={{ cursor: "pointer" }}
                            >
                              <ListItemIcon>
                                <AssignmentIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={role.roleName}
                                primaryTypographyProps={{
                                  sx: { fontSize: 16 },
                                }}
                              />
                            </ListItem>
                          ))
                        ) : (
                          <Typography align="center">
                            No roles assigned
                          </Typography>
                        )}
                      </List>
                    )}
                    {tabValue === 1 && (
                      <List>
                        {getAllConsolidatedPermissions(selectedUser).length >
                        0 ? (
                          getAllConsolidatedPermissions(selectedUser).map(
                            (permission) => (
                              <ListItem
                                key={permission}
                                onClick={() => detailsofPermission(permission)}
                                sx={{ cursor: "pointer" }}
                              >
                                <ListItemIcon>
                                  <PolicyIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    permission.split("/").length > 1
                                      ? permission.split("/")[1]
                                      : permission
                                  }
                                  primaryTypographyProps={{
                                    sx: { fontSize: 16 },
                                  }}
                                />
                              </ListItem>
                            ),
                          )
                        ) : (
                          <Typography align="center">
                            No permissions assigned
                          </Typography>
                        )}
                      </List>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Stack>
        )}
      </Box>

      <Dialog
        open={rolesDetailsModalOpen}
        onClose={() => setRolesDetailsModalOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Role Details</DialogTitle>
        <DialogContent dividers>
          <Wrapper className={classes.root}>
            <Grid container spacing={2}>
              {rolesPermissionJson.map((role, index) => (
                <Grid item xs={12} key={index}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "16px" }}
                  >
                    <strong>Role Name: </strong> {role.RoleName}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "16px" }}
                  >
                    <strong>Role ID: </strong> {role.RoleId}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "16px" }}
                  >
                    <strong> Created Date:</strong> {role.CreatedDate}
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "16px" }}>
                    <strong>Policies: </strong>
                  </Typography>
                  <List>
                    {role.policies.map((policy, index) => (
                      <React.Fragment key={index}>
                        <ListItem>
                          <ListItemText
                            primary={`Policy Name: ${policy.PolicyName}`}
                            secondary={`Policy ID: ${policy.PolicyId}`}
                            primaryTypographyProps={{ sx: { fontSize: 16 } }}
                          />
                        </ListItem>
                        <Typography sx={{ fontSize: 16, mt: 2 }}>
                          <strong>Policy Document:</strong>
                        </Typography>
                        {policy.PolicyDocument && (
                          <ListItem
                            primaryTypographyProps={{ sx: { fontSize: 16 } }}
                          >
                            <Paper
                              elevation={3}
                              style={{ padding: "5px", margin: "5px" }}
                            >
                              <Typography sx={{ fontSize: 16 }}>
                                <strong>Version:</strong>{" "}
                                {policy.PolicyDocument.Version}
                              </Typography>
                              {policy.PolicyDocument.Statement.map(
                                (statement, index) => (
                                  <Grid
                                    container
                                    key={index}
                                    spacing={2}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="body2"
                                        sx={{ fontSize: 16 }}
                                      >
                                        <strong>Statement {index + 1}:</strong>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Sid:</strong> {statement.Sid}
                                      </Typography>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Effect:</strong>{" "}
                                        {statement.Effect}
                                      </Typography>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Action:</strong>{" "}
                                        <List disablePadding>
                                          {statement.Action?.map(
                                            (action, index) => (
                                              <ListItem
                                                key={index}
                                                disableGutters
                                                sx={{ padding: 0 }}
                                              >
                                                <ListItemText>
                                                  <Typography
                                                    sx={{ fontSize: 16 }}
                                                  >
                                                    {action}
                                                  </Typography>
                                                </ListItemText>
                                              </ListItem>
                                            ),
                                          )}
                                        </List>
                                      </Typography>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Resource:</strong>{" "}
                                        {Array.isArray(statement.Resource) ? (
                                          <List>
                                            {statement.Resource.map(
                                              (resource, index) => (
                                                <ListItem
                                                  key={index}
                                                  disableGutters
                                                  sx={{ padding: 0 }}
                                                >
                                                  <ListItemText>
                                                    <Typography
                                                      sx={{ fontSize: 16 }}
                                                    >
                                                      {resource}
                                                    </Typography>
                                                  </ListItemText>
                                                </ListItem>
                                              ),
                                            )}
                                          </List>
                                        ) : (
                                          <Typography sx={{ fontSize: 16 }}>
                                            {statement.Resource}
                                          </Typography>
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ),
                              )}
                            </Paper>
                          </ListItem>
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Wrapper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setRolesDetailsModalOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={policiesDetailsModalOpen}
        onClose={() => setPoliciesDetailsModalOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Policy Details</DialogTitle>
        <DialogContent dividers>
          <Wrapper className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontSize: "16px" }}>
                  <strong>Policies: </strong>
                </Typography>
                <List>
                  {policiesDetails?.map((policy, index) => (
                    <React.Fragment key={index}>
                      <ListItem>
                        <ListItemText
                          primary={policy.PolicyName}
                          secondary={`Policy ID: ${policy.PolicyId}`}
                          primaryTypographyProps={{ sx: { fontSize: 16 } }}
                        />
                      </ListItem>
                      <Typography sx={{ fontSize: 16, mt: 2 }}>
                        <strong>Policy Document:</strong>
                      </Typography>
                      {policy.PolicyDocument && (
                        <ListItem
                          primaryTypographyProps={{ sx: { fontSize: 16 } }}
                        >
                          <Paper
                            elevation={3}
                            style={{ padding: "5px", margin: "5px" }}
                          >
                            <Typography sx={{ fontSize: 16 }}>
                              <strong>Version:</strong>{" "}
                              {policy.PolicyDocument.Version}
                            </Typography>
                            {policy.PolicyDocument.Statement.map(
                              (statement, index) => (
                                <Grid
                                  container
                                  key={index}
                                  spacing={2}
                                  style={{ marginTop: "10px" }}
                                >
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body2"
                                      sx={{ fontSize: 16 }}
                                    >
                                      <strong>Statement {index + 1}:</strong>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    {statement.Sid && (
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Sid:</strong> {statement.Sid}
                                      </Typography>
                                    )}

                                    <Typography sx={{ fontSize: 16 }}>
                                      <strong>Effect:</strong>{" "}
                                      {statement.Effect}
                                    </Typography>
                                    <Typography sx={{ fontSize: 16 }}>
                                      <strong>Action:</strong>{" "}
                                      <List disablePadding>
                                        {Array.isArray(statement.Action) ? (
                                          statement.Action?.map(
                                            (action, index) => (
                                              <ListItem
                                                key={index}
                                                disableGutters
                                                sx={{ padding: 0 }}
                                              >
                                                <ListItemText>
                                                  <Typography
                                                    sx={{ fontSize: 16 }}
                                                  >
                                                    {action}
                                                  </Typography>
                                                </ListItemText>
                                              </ListItem>
                                            ),
                                          )
                                        ) : (
                                          <Typography sx={{ fontSize: 16 }}>
                                            {statement.Action}
                                          </Typography>
                                        )}
                                      </List>
                                    </Typography>
                                    <Typography sx={{ fontSize: 16 }}>
                                      <strong>Resource:</strong>{" "}
                                      {Array.isArray(statement.Resource) ? (
                                        <List>
                                          {statement.Resource.map(
                                            (resource, index) => (
                                              <ListItem
                                                key={index}
                                                disableGutters
                                                sx={{ padding: 0 }}
                                              >
                                                <ListItemText>
                                                  <Typography
                                                    sx={{ fontSize: 16 }}
                                                  >
                                                    {resource}
                                                  </Typography>
                                                </ListItemText>
                                              </ListItem>
                                            ),
                                          )}
                                        </List>
                                      ) : (
                                        <Typography sx={{ fontSize: 16 }}>
                                          {statement.Resource}
                                        </Typography>
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ),
                            )}
                          </Paper>
                        </ListItem>
                      )}
                    </React.Fragment>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Wrapper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setPoliciesDetailsModalOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AWSGroups;
