import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { NGROK } from "../../../APIs";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const InviteModal = ({
  open,
  closeModal,
  tenantName,
  openSuccessInvite,
  openFailedInvite,
}) => {
  const callToInvite = async (values, { setSubmitting }) => {
    try {
      const email = values.email.toLowerCase().trim();

      const response = await axios.post(`${NGROK}/api/invite/send`, {
        ...values,
        email,
        tenantName,
      });
      let { isOk } = response.data;
      if (isOk) {
        openSuccessInvite("Invite Sent Successfully");
        closeModal();
      } else {
        openFailedInvite("Invite already sent to this mail");
        closeModal();
      }
    } catch (error) {
      console.error("There was an error inviting the user", error);
      openFailedInvite("Failed to send invite. Try later.");
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={closeModal}
        aria-labelledby="user-invite-dialog-title"
      >
        <DialogTitle id="user-invite-dialog-title">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InsertInvitationIcon sx={{ mr: 1 }} />
              User Invite
            </Box>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{ firstName: "", lastName: "", email: "" }}
            validationSchema={validationSchema}
            onSubmit={callToInvite}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form id="invite-form">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Field
                    as={TextField}
                    name="firstName"
                    label="First Name"
                    variant="outlined"
                    error={touched.firstName && !!errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                    fullWidth
                    margin="normal"
                    sx={{ mr: 1 }}
                  />
                  <Field
                    as={TextField}
                    name="lastName"
                    label="Last Name"
                    variant="outlined"
                    error={touched.lastName && !!errors.lastName}
                    helperText={touched.lastName && errors.lastName}
                    fullWidth
                    margin="normal"
                    sx={{ ml: 1 }}
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Field
                    as={TextField}
                    name="email"
                    label="Email"
                    variant="outlined"
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    fullWidth
                    margin="normal"
                  />
                </Box>
                <DialogActions>
                  <Button onClick={closeModal} color="secondary">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    form="invite-form"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Invite
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InviteModal;
